import React from 'react';

import WPI_intro_photo from '../img/general/WPI_intro_photo.jpg';

const HeaderImage = ({ children }) => {
    return (
        <section id="intro" className="relative h-[550px]">
            <img
                className="w-screen h-full object-cover"
                src={WPI_intro_photo}
                alt="WPI"
            />
            <div className="absolute top-0 left-0 w-screen h-full bg-black opacity-30" />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-2 flex flex-col space-y-4 items-center">
                {children}
            </div>
        </section>
    );
};

export default HeaderImage;

import React from 'react';
import HeaderImage from './HeaderImage';

const Events = () => {
    return (
        <section id="events">
            <HeaderImage>
                <h2 className="text-6xl font-bold text-center mb-8 text-white">Events</h2>
                <div className="mb-12 text-center text-white">
                    <p className="mb-4">Looking to stay updated? Our events offer a chance to connect, learn, and engage with like-minded individuals. Don't miss out on the latest happenings!</p>
                    <p className="mb-6">Here’s what’s coming up:</p>
                </div>
            </HeaderImage>
            <div className="container mx-auto px-6 py-16">
                <div className="grid md:grid-cols-2 gap-8">
                    {/* Stock Market Game */}
                    <div className="bg-white shadow-md p-6 rounded-lg">
                        <h3 className="text-xl font-semibold mb-4">Stock Market Game</h3>
                        <p className="mb-4">Manage a simulated portfolio of $10,000 for four weeks and win up to $100!</p>
                        <h4 className="font-medium mb-2">Registration</h4>
                        <ol className="list-decimal list-inside mb-4">
                            <li>Go to <a href="http://www.investopedia.com/simulator/"  >Investopedia Stock Simulator</a>, and register for an account.</li>
                            <li>On the <a href="http://www.investopedia.com/simulator/"  >Simulator</a> page, click "View More Games" under "Join a Public Game".</li>
                            <li>Search by “Game Name” and type in “WPI”.</li>
                            <li>Find “WPI IA 2016 Campus Game” and click “Join”.</li>
                            <li>When prompted for a password, enter "Goatbucks2016".</li>
                            <li>Learn more through our <a href="#learn"  >resources</a>.</li>
                            <li>Have fun! The game runs from March 21st - April 22nd.</li>
                        </ol>
                        <h4 className="font-medium mb-2">Rules</h4>
                        <ul className="list-disc list-inside space-y-1">
                            <li>Late entries are allowed – join anytime!</li>
                            <li>Margin trading, short selling, and options trading are allowed.</li>
                            <li>Cannot sell securities within 15 minutes of purchase.</li>
                            <li>No single security can make up more than 50% of initial capital.</li>
                            <li>Complete rules can be found <a href="https://docs.google.com/document/d/1I5wh9oPMoIay-LSIxNMPu2is07q1DWGKtISazwu7QTg/edit?usp=sharing"  >here</a>.</li>
                            <li>Individual portfolios, trades, and ranks are visible to all players.</li>
                        </ul>
                    </div>
                    
                    {/* Automated Trading Competition */}
                    <div className="bg-white shadow-md p-6 rounded-lg">
                        <h3 className="text-xl font-semibold mb-4">Automated Trading Competition</h3>
                        <p className="mb-4">Develop an algorithm to trade with a simulated portfolio of $10,000 for four weeks, and win up to $100!</p>
                        <h4 className="font-medium mb-2">Registration</h4>
                        <ol className="list-decimal list-inside mb-4">
                            <li>Register for the competition <a href="https://www.investopedia.com/simulator/games/my-games"  >here!</a></li>
                            <li>Create an Investopedia Stock Simulator account.</li>
                            <li>Join the game using the password "Automate2016".</li>
                            <li>Learn more by attending our upcoming Automated Trading Crash Course.</li>
                        </ol>
                        <h4 className="font-medium mb-2">Rules</h4>
                        <ul className="list-disc list-inside space-y-1">
                            <li>Late entries are allowed – join anytime!</li>
                            <li>Margin trading, short selling, and options trading are allowed.</li>
                            <li>Algorithm must be coded in Python, C++, or Java.</li>
                            <li>No single security can make up more than 50% of initial capital.</li>
                            <li>Complete rules can be found <a href="https://docs.google.com/document/d/1-PmWw7wv0k-wvQYXl23NcFvok8eHc6oIOuvEFJhtRDI/edit?usp=sharing"  >here</a>.</li>
                            <li>Individual portfolios, trades, and ranks are visible to all players.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Events;

import React from 'react';
import HeaderImage from './HeaderImage';

const EventSection = ({ title, description }) => {
    return (
        <div class="flex items-center justify-center px-6 md:px-60">
            <div class="relative border-l-4 border-dotted">
                <div class="mt-6 px-4">
                    <div className="bg-white shadow-md p-6 rounded-lg container mx-auto">
                        <h3 className="text-xl font-semibold mb-4">{title}</h3>
                        <p className="mb-4">{description}</p>
                    </div>
                </div>
                <div class="absolute top-[50%] -ml-2.5 h-4 w-4 rounded-full bg-red-600"/>
            </div>
        </div>

    );
};

<div className="bg-white shadow-md p-6 rounded-lg container mx-auto pt-16">
            <div>
                
                <div class="absolute top-0 -ml-1.5 h-4 w-4 rounded-full bg-blue-600"></div>
            </div>
        </div>

const Events = () => {
    return (
        <section id="events" className="pb-16">
            <HeaderImage>
                <h2 className="text-6xl font-bold text-center mb-8 text-white">History</h2>
                <div className="mb-12 text-center text-white">
                    <p className="mb-4">Learn more about the history of the WPI Investing Association, and its recently launched student-run fund!</p>
                    <p>Here's our history:</p>
                </div>
            </HeaderImage>

            <div className="pt-16">
                <EventSection 
                    title="History" 
                    description="Since being founded in 2016, the WPI Investing Association grew from a small team of 4 to over 180 members in a short amount of time. Initially, the Investing Association served to provide WPI students with the baseline knowledge needed to start delving into personal finance and investing. Members would do mock stock pitches, compete in mock stock market games for prizes, and have recently been able to bring members for a night in Wall Street. However, as time went on, and as the prominence of the WPI Business School & its Financial Technology intiatives grew, it became clear the club needed to start expanding." 
                    />
                <EventSection
                    title="Expansion"
                    description="Seeing that WPI is a heavily STEM based school, the executive board decided to take advantage of this, and dive into Financial Technology initiatives. This included bringing on new exec roles such as Machine Learning Engineers. Not only would students be able to participate in collaborative side projects, but they would also receive hands on experience working in a team to produce a product that would eventually be used by the Investing Association. This proved vital comes the start of the Student-led fund."
                    />
                <EventSection
                    title="Fund Structure"
                    description="Starting in 2024, the Investing Association was belated to announce they would be able to gain hands on experience investing with real money with the launch of a student-run fund, with money from the WPI endownment being used by students. In order to ensure layers of security within the fund, interested members first take a course on finance, stock market structure, and its intersection with technology. In this course they gain the necessary skills to work within a team to find what makes a good investment. From there,students go through an informal interview process with an executive board member to se if they are deemed fit to join the fund. Once accepted, the analyst gets assigned a sector, and eventually create a stock pitch. This pitch is presented to the members of the fund, and members decide how much they want to invest, if it all. This decision is brought to our governing board, including those of the Board of Trustees, industry professionals, faculty, and alumni. If the board sees the trade as beneficial to the fund, they execute the order. If not, they provide recommendations on investment size or alternative investment opportunities."
                    />
                <EventSection
                    title="Goals of the Investing Association"
                    description= {<ol className="list-decimal list-inside mb-4">
                                    <li>To provide students of all backgrounds with an adequate education in personal finance & investing</li>
                                    <li>To allow students interested in exploring and innovating within the world of finance & technology to do so, with group projects used by our analysts</li>
                                    <li>To provide consistent returns for the student-led fund, aiming to beat the S&P 500 every calendar year.</li>
                                    <li>To surround students with a group of like minded individuals with similar goals</li>
                                    <li>To build life long relationships and connections with members, alumni, industry professionals, and other students</li>
                                    <li>Have fun! </li>
                                </ol>}
                    /> 
                </div>
        </section>
    );
};

export default Events;

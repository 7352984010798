import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import NavBar from './components/NavBar';
import Landing from './components/Landing';
import GetInvolved from './components/GetInvolved';
import History from './components/History';
import Events from './components/Events';
import OurTeam from './components/OurTeam';
import ProfilePage from './components/ProfilePage';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './index.css';

// Hook to change the page title
const usePageTitle = () => {
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        document.title = 'Home - investWPI';
        break;
      case '/get_involved':
        document.title = 'Get Involved - investWPI';
        break;
      case '/history':
        document.title = 'History - investWPI';
        break;
      case '/events':
        document.title = 'Events - investWPI';
        break;
      case '/learn':
        document.title = 'Learn - investWPI';
        break;
      case '/team':
        document.title = 'Our Team - investWPI';
        break;
      case '/contact':
        document.title = 'Contact - investWPI';
        break;
      default:
        document.title = 'investWPI';
    }
  }, [location]);
};

const AppContent = () => {
  usePageTitle();

  return (
    <main className="flex-grow">
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/get_involved" element={<GetInvolved />} />
        <Route path="/history" element={<History />} />
        <Route path="/events" element={<Events />} />
        <Route path="/team" element={<OurTeam />} />
        <Route path="/profile/:memberId" element={<ProfilePage />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </main>
  );
}

const App = () => {
  return (
    // Needed to wrap in a Router for the AppContent to work
    <Router>
      <div className="flex flex-col min-h-screen">
        <NavBar />
        <AppContent />
        <Footer />
      </div>
    </Router>
  );
}

export default App;

import React from 'react';
import { Link } from 'react-router-dom';
import HeaderImage from './HeaderImage';

// Images
import PlaceholderMan from '../img/headshots/PlaceholderMan.jpg';
import PlaceholderWoman from '../img/headshots/PlaceholderWoman.jpg';
import SyedPhoto from '../img/headshots/syed.png';
import SarniePhoto from '../img/headshots/sarnieImage.jpeg';
import ArtemPhoto from '../img/headshots/artemPhoto.jpeg';
import ConnorPhoto from '../img/headshots/connorJason.jpeg';
import FalcaoPhoto from '../img/headshots/Falcao.jpeg';
import MaysaPhoto from '../img/headshots/maysa.jpeg';
import DunbarPhoto from '../img/headshots/dunbarImage.jpeg';
import IanPhoto from '../img/headshots/IanPhoto.jpeg';
import AnandPhoto from '../img/headshots/anand.jpeg';
import Doanh from '../img/headshots/doanh.jpeg';

const OurTeam = () => {
    const teamSections = {

        FacultyAdvisors:[
            // Faculty Advisors
            { name: 'Professor Dunbar', role: 'Faculty Advisor', linkedIn: 'https://www.linkedin.com/in/dr-kwamie-dunbar-ph-d-a65b393/', description: 'Professor Dunbar, an Associate Professor of Finance at WPI, has been the faculty advisor of the WPI Investing Association since 2020. Previously working as the Director of Risk Analytics at Mastercard, Professor Dunbar has extensive experience to lead the team along the way.  ', image: DunbarPhoto, email: "kdunbar@wpi.edu" },
            // { name: 'Professor Sarnie', role: 'Faculty Advisor', linkedIn: 'https://www.linkedin.com/in/rob-sarnie/', description: 'Professor Sarnie, a Professor of Practice of Finance, Fintech, and Management Information Systems at WPI, has spearheaded initatives of the Investing Association since 2020. Previously worked for Fidelity as the Vice President of IT Strategy and Planning', image: SarniePhoto, email: "rsarnie@wpi.edu" },
        ],
        ExecutiveBoard: [
            // Executive Board members
            { name: 'Syed Shazli', role: 'President', linkedIn: 'https://www.linkedin.com/in/syed-shazli/', description: 'As President, Syed Shazli leads the Investing Association, driving strategic vision and overseeing major initiatives. With a deep passion for finance, he plays a crucial role in shaping the organization’s direction.', image: SyedPhoto, email: "sashazli@wpi.edu" },
            { name: 'Evan Mandel', role: 'Vice President', description: 'Evan Mandel, the Vice President, is integral in supporting the President and managing the day-to-day operations of the association. His early start in investing and recent internship experience further enhances his leadership.', image: PlaceholderMan, email: "emandel@wpi.edu"},
            { name: 'Anand Pagnis', role: 'Secretary', linkedIn: 'https://www.linkedin.com/in/anand-pagnis-96927a24a/', description: 'Anand Pagnis serves as Secretary, ensuring effective communication and documentation within the association. His organizational skills are vital in maintaining the smooth operation of the club’s activities.', image: AnandPhoto, email: "arpagnis@wpi.edu" },
            { name: 'Artem Frenk', role: 'Treasurer', linkedIn: 'https://www.linkedin.com/in/artem-frenk/', description: "In addition to being our lead Machine Learning Engineer, Artem is our treasurer, who deals with all finances for the club. His involvement is invaluable to our success ", image: ArtemPhoto, email: "" },
           
        ],
        Leadership: [
            // Leadership team members
            { name: 'Connor Jason', role: 'Director of Technology Operations', linkedIn: 'https://www.linkedin.com/in/connor-jason/', description: 'Connor Jason is the Director of Technology Operations. He manages the club’s tech infrastructure, overseeing website maintenance, database management, and the integration of new technologies. Connor ensures that our digital systems run smoothly and stay current with the latest trends, supporting the club’s mission and enhancing member engagement.', image: ConnorPhoto, email: "cejason@wpi.edu" },
            { name: 'Maysa Fateh', role: 'Director of Marketing', linkedIn: 'https://www.linkedin.com/in/maysa-fateh/', description: 'Maysa Fateh is the Director of Marketing. She crafts and implements marketing strategies to enhance the association’s visibility and engage with the community effectively.', image: MaysaPhoto, email: "mfateh@wpi.edu" },
            { name: 'Artem Frenk', role: 'Lead Machine Learning Engineer', linkedIn: 'https://www.linkedin.com/in/artem-frenk/', description: 'As the Lead Machine Learning Engineer, Artem Frenk spearheads machine learning projects and data analysis efforts, applying advanced algorithms to support the association’s technological needs.', image: ArtemPhoto, email: "afrenk@wpi.edu" },
        ],
        Analysts: [
            // Analysts team members
            { name: 'Ian Green', role: 'Visiting Analyst', linkedIn: 'https://www.linkedin.com/in/ian-green-3b2677293/', description: "Ian Green, a Visiting Analyst from Bentley University, brings valuable experience from his Private Equity Internship at TVD Capital, contributing fresh insights and analysis to the team. He is a current Wealth Management Intern at Sterling Oak Wealth Management, and is a analyst for Bentley University's top student led funds", image: IanPhoto , email: "itgreen@wpi.edu" },            
            { name: 'Mohammad Ellahi', role: 'Analyst', linkedIn: 'https://www.linkedin.com/in/mohammad-ellahi-ab215223a/', description: 'Mohammad Ellahi is an Analyst with a passion for finance that began during his freshman year at the Investing Association. He plays a key role in financial research and analysis.', image: PlaceholderMan, email: "maellahi@wpi.edu" },
            { name: 'Mohammed Musawwir', role: 'Junior Analyst', linkedIn: 'https://www.linkedin.com/in/mohammed-musawwir-348715220/', description: 'As a Junior Analyst, Mohammed Musawwir supports the team with research and analytical tasks, contributing to the association’s ongoing projects and financial evaluations.', image: PlaceholderMan, email: "mmusawwir@wpi.edu" },
        ],
        Engineers: [
            // Engineers team members
            { name: 'Ethan Vaz Falcão', role: 'Machine Learning Engineer', linkedIn: 'https://www.linkedin.com/in/ethan-falcao/', description: 'Ethan Vaz Falcão is a Machine Learning Engineer, specializing in developing and implementing machine learning models to enhance the association’s technological capabilities.', image: FalcaoPhoto, email: "ejvazfalcao@wpi.edu" },
            { name: "Doanh Phung", role: "Web Developer", linkedIn: "https://www.linkedin.com/in/doanh-phung-21583a1b2/", description: "Doanh Phung is a Web Developer responsible for creating and maintaining the association’s website, ensuring a seamless and engaging online experience for members and visitors.", image: Doanh, email: "lphung@wpi.edu"},
        ],
    };

    return (
        <section id="team">
            <HeaderImage>
                <h2 className="text-6xl font-bold mb-8 text-center text-white w-[100vw]">Meet the Team</h2>
                <div className="mb-12 text-center text-white w-[80vw]">
                    <p className="mb-4">Curious about who’s behind the scenes? Meet the dedicated individuals who make our work possible. Our team is passionate, skilled, and ready to make a difference!</p>
                    <p className="mb-6">Get to know us:</p>
                </div>
            </HeaderImage>
            <div className="container mx-auto py-16">
                {/* Iterate over each team section */}
                {Object.keys(teamSections).map((sectionKey, index) => (
                    <div key={index} className="mb-16">
                        <h3 className="text-2xl font-bold mb-4 text-center">
                            {/* Put a space in the section title if needed */}
                            {sectionKey.replace(/([A-Z])/g, ' $1').trim()}
                        </h3>
                        <div className="flex flex-wrap justify-center gap-4">
                            {/* Iterate over each member in the current section */}
                            {teamSections[sectionKey].map((member, index) => (
                                <Link key={index} to={`/profile/${member.name.replace(/\s+/g, '-').toLowerCase()}`} state={{ name: member.name, image: member.image, description: member.description, linkedIn: member.linkedIn, email: member.email }} className="team-member text-center md:w-56 w-2/5 bg-white p-6 rounded-lg shadow-md hover:scale-105 transition-transform">
                                    <img src={member.image} alt={member.name} className="w-full rounded-full mb-4" />
                                    <h4 className="text-xl text-black font-semibold">{member.name}</h4>
                                    <p className="text-red-600 hover:text-red-600">{member.role}</p>
                                </Link>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default OurTeam;

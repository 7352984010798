import React from 'react';
import { Link } from 'react-router-dom';

// Images
import TeamPhoto1 from '../img/team/TeamPhoto1.jpg';

import FidelityLogo from '../img/companies/Fidelity.png';
import JPMorganLogo from '../img/companies/JPMorgan.png';
import GoldmanSachsLogo from '../img/companies/GoldmanSachs.png';
import BridgewaterAssociatesLogo from '../img/companies/BridgewaterAssociates.png';
import StateStreetLogo from '../img/companies/StateStreet.png';
import CitizensBankLogo from '../img/companies/CitizensBank.png';
import JohnHancockLogo from '../img/companies/JohnHancock.png';
import AppleLogo from '../img/companies/Apple.png';
import NetflixLogo from '../img/companies/Netflix.png';
import TiktokLogo from '../img/companies/TikTok.png';
import OptionsGroupLogo from '../img/companies/OptionsGroup.png';
import TeslaLogo from '../img/companies/tslaLogo.png';
import MicrosoftLogo from '../img/companies/Microsoft.png';
import HeaderImage from './HeaderImage';
import GoogleLogo from '../img/companies/Google.png';
import SnapchatLogo from '../img/companies/Snapchat.png';
import AmazonLogo from '../img/companies/Amazon.png';
import JeffriesLogo from '../img/companies/Jeffries.png';
import BakerHughes from '../img/companies/BakerHughes.png';
import GeneralElectric from '../img/companies/GeneralElectric.png';
import GunvorGroup from '../img/companies/GunvorGroup.png';

//founding companies
import HoamsyLogo from '../img/companies/hoamsy.png';
import EllipsisLogo from '../img/companies/ellipsis.png';
import ArchLogo from '../img/companies/Arch_Lending_Logo.png';
import SevenELogo from '../img/companies/7eInvestments.png';

// investWPI logo and text under it
const Intro = () => {
    return (
        <HeaderImage>
            <div className="text-6xl font-bold">
                    <span className="text-red-600 z-10">invest</span>
                    <span className="text-white">WPI</span>
                </div>
                <div>
                    <p className="text-2xl text-white z-10 text-center">
                        Your gateway to professional finance and investing experiences at WPI
                    </p>
                </div>
        </HeaderImage>
    );
};


// Who we are section
const WhoWeAre = () => {
    return (
        <section id="who-we-are" className="section py-16">
            <div className="container mx-auto text-center px-6">
                <h2 className="text-2xl font-bold mb-4">Who We Are</h2>
                <p>
                    The WPI Investing Association (WPI IA) is a student-led organization at Worcester Polytechnic Institute where students engage in managing a non-profit student led hedge fund. Members participate in an educational curriculum focused on stock valuation, portfolio management, and automated trading, before being accepted as an analyst for a specific sector of the market.
                </p>
                <p className="mt-4">
                    Members are also able to attend our annual Wall Street trip, where students spend a night in New York City, and then attend the opening bell at the New York Stock Exchange, as well as visit the Federal Reserve and companies such as New York Life. Additonally, students compete in stock pitch competitions and stock market games, enhancing their practical investing skills. Since its inception in 2016, WPI IA has provided invaluable learning opportunities for WPI students.
                </p>
                <p className="mt-4">
                    The association also aims to help students become financially responsible, offering workshops on student loans, investing, and career preparation.
                </p>
            </div>
        </section>
    );
};

//company founders
const Founders = () => {
    const logoFounder = {
        Ellipsis: EllipsisLogo,
        Arch: ArchLogo,
        Hoamsy: HoamsyLogo,
        '7E Investments': SevenELogo,
        
    };

    const companies = Object.keys(logoFounder);

    return (
        <section id="placements" className="section py-16">
            <div className="container mx-auto text-center">
                <h2 className="text-2xl font-bold mb-4">Company Founders</h2>
                <div className="flex flex-wrap justify-center gap-4 pt-4">
                    {companies.map((company, index) => (
                        <div key={index} className="bg-white text-gray-700 p-6 rounded-lg md:w-48 md:h-48 w-[35vw] h-[35vw] flex justify-center items-center">
                            <img src={logoFounder[company]} alt={company} className="w-full h-auto object-cover" />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}


// Job placements
const Placements = () => {
    const logos = {
        Fidelity: FidelityLogo,
        'JP Morgan': JPMorganLogo,
        'Goldman Sachs': GoldmanSachsLogo,
        'Bridgewater Associates': BridgewaterAssociatesLogo,
        'State Street': StateStreetLogo,
        'Options Group': OptionsGroupLogo,
        'Citizens Bank': CitizensBankLogo,
        'John Hancock': JohnHancockLogo,
        Apple: AppleLogo,
        Amazon: AmazonLogo,
        Google: GoogleLogo,
        Netflix: NetflixLogo,
        Snapchat: SnapchatLogo,
        Microsoft: MicrosoftLogo,
        Tesla: TeslaLogo,
        Tiktok: TiktokLogo,
        Jeffries: JeffriesLogo,
        'Baker Hughes': BakerHughes,
        'General Electric': GeneralElectric,
        'Gunvor Group': GunvorGroup,
    };

    

    const companies = Object.keys(logos);

    return (
        <section id="placements" className="section py-16">
            <div className="container mx-auto text-center">
                <h2 className="text-2xl font-bold mb-4">Job Placements</h2>
                <div className="flex flex-wrap justify-center gap-4 pt-4">
                    {companies.map((company, index) => (
                        <div key={index} className="bg-white text-gray-700 p-6 rounded-lg md:w-48 md:h-48 w-[35vw] h-[35vw] flex justify-center items-center">
                            <img src={logos[company]} alt={company} className="w-full h-auto object-cover" />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};





// Link to our reports
const ReportsSection = () => {
    return (
        <section id="reports" className="section py-16">
            <div className="container mx-auto text-center">
                <h2 className="text-2xl font-bold mb-4">Check out our Reports</h2>
                <p className="mb-4">
                    The Investing Association releases reports every semester regarding alumni, events, portfolio positions, and more.
                </p>
                <a href="https://drive.google.com/drive/u/0/folders/0Bzr5xNYCT18aQ201YmFZeEZwQk0?resourcekey=0-CmgE9yGsZu4xk6SKSBDKdA"  className="group/button absolute left-1/2 transform -translate-x-1/2 inline-flex items-center justify-center overflow-hidden rounded-md bg-red-600 backdrop-blur-lg px-6 py-2 text-base font-semibold text-white transition-all duration-300 ease-in-out hover:shadow-xl hover:shadow-gray-600/50 hover:text-white border border-white/20">
                    <span>View Reports</span>
                    <div  className="absolute inset-0 flex h-full w-full justify-center [transform:skew(-13deg)_translateX(-100%)] group-hover/button:duration-1000 group-hover/button:[transform:skew(-13deg)_translateX(100%)]">
                        <div  className="relative h-full w-10 bg-white/20"></div>
                    </div>
                </a>
            </div>
        </section>
    );
};

// Link to our team page
const MeetTheTeam = () => {
    return (
        <section id="team" className="section py-12">
            <div className="relative text-center">
                <img className="w-full h-[30vh] md:h-[40vh] object-cover" src={TeamPhoto1} alt="Team" />
                <div id="glass" className="absolute inset-0 z-1"></div>
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-2 flex flex-col space-y-4 items-center">
                    <p className="text-white font-bold">
                        The WPI Investing Association has 182 members who work together to manage our fund.
                    </p>
                    <Link to="/team"  className="group/button relative inline-flex items-center justify-center overflow-hidden rounded-md bg-red-600 backdrop-blur-lg px-6 py-2 text-base font-semibold text-white transition-all duration-300 ease-in-out hover:shadow-xl hover:shadow-gray-600/50 hover:text-white border border-white/20">
                        <span>Meet the Team</span>
                        <div  className="absolute inset-0 flex h-full w-full justify-center [transform:skew(-13deg)_translateX(-100%)] group-hover/button:duration-1000 group-hover/button:[transform:skew(-13deg)_translateX(100%)]">
                            <div  className="relative h-full w-10 bg-white/20"></div>
                        </div>
                    </Link>
                </div>
            </div>
        </section>
    );
};

const ContactUs = () => {
    return (
        <section id="contact" className="section py-12 pb-32">
            <div className="container mx-auto text-center">
                <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
                <p className="mb-4">Have any questions? Feel free to reach out to us.</p>
                <Link to="/contact" className="group/button absolute left-1/2 transform -translate-x-1/2 inline-flex items-center justify-center overflow-hidden rounded-md bg-red-600 backdrop-blur-lg px-6 py-2 text-base font-semibold text-white transition-all duration-300 ease-in-out hover:shadow-xl hover:shadow-gray-600/50 hover:text-white border border-white/20">
                    <span>Contact Us</span>
                    <div  className="absolute inset-0 flex h-full w-full justify-center [transform:skew(-13deg)_translateX(-100%)] group-hover/button:duration-1000 group-hover/button:[transform:skew(-13deg)_translateX(100%)]">
                        <div  className="relative h-full w-10 bg-white/20"></div>
                    </div>
                </Link>
            </div>
        </section>
    );
}


// Compile the whole page
const Landing = () => {
    return (
        <>
            <Intro />
            <WhoWeAre />
            <Founders />
            <Placements />
            <ReportsSection />
            <MeetTheTeam />
            <ContactUs />
        </>
    );
};

export default Landing;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderImage from './HeaderImage';

// gbm pictures
import wong1 from '../img/general_body_meetings/wong1.png';
import wong2 from '../img/general_body_meetings/wong2.png';
import syed1 from '../img/general_body_meetings/syed1.png';
import syed2 from '../img/general_body_meetings/syed2.png';

// fund related events pictures
import WTC from '../img/fund_related_events/WTC.jpeg';
import IAwomen from '../img/fund_related_events/IAwomen.jpeg';
import NYStockExchange from '../img/fund_related_events/NYStockExchange.jpg';
import triangleBuilding from '../img/fund_related_events/triangleBuilding.jpeg';
import WongAndSarnie from '../img/fund_related_events/WongAndSarnie.jpeg';
import NYSEgroup from '../img/fund_related_events/NYSEgroup.jpeg';

// upcoming events pictures
import SyedTickets from '../img/events/SyedTickets.png';
import CasinoRoom1 from '../img/events/CasinoRoom1.png';
import Food from '../img/events/Food.png';
import CasinoRoom2 from '../img/events/CasinoRoom2.png';

// future projects pictures

// membership pictures

// sponsorship pictures

const GridItems = ({ title, description, link, linkDescription }) => {
    return (
        <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4">{title}</h3>
            <p className="text-base mb-4">{description}</p>
            <a href={`#${link}`}>{linkDescription}</a>
        </div>
    );
};


const ImageCarousel = ({ images }) => {
    const [startIndex, setStartIndex] = useState(0);
    const numVisible = 4;

    // right arrow function
    const handleNext = () => {
        setStartIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    // left arrow function
    const handlePrevious = () => {
        setStartIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    // slicing logic to display images and correctly handle list
    const visibleImages = images.slice(startIndex, startIndex + numVisible);
    if (images.length > 0 && visibleImages.length < numVisible) {
        visibleImages.push(...images.slice(0, numVisible - visibleImages.length));
    }

    return (
        <div className="relative flex items-center justify-center mt-8">
            {/* Left Arrow */}
            {images.length > 1 && (
                <button
                    className=" mr-4 p-2 text-xl bg-gray-300 rounded-full hover:bg-gray-400 focus:outline-none"
                    onClick={handlePrevious}
                >
                    &lt;
                </button>
            )}

            {/* Image Display */}
            <div className="flex gap-4 overflow-hidden max-w-full pr-4">
                {visibleImages.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`${index + 1}`}
                        className="w-48 h-48 object-cover rounded-lg shadow-md"
                    />
                ))}
            </div>

            {/* Right Arrow */}
            {images.length > 1 && (
            <button
                className="ml-4 md:ml-0 p-2 text-xl bg-gray-300 rounded-full hover:bg-gray-400 focus:outline-none"
                onClick={handleNext}
            >
                &gt;
            </button>
            )}
        </div>
    );
};


const Sections = ({ id, title, paragraph, images = [] }) => {
    return (
        <div id={id} className="section mb-8 text-center my-24">
            <h2 className="mx-auto text-4xl font-bold mb-4">{title}</h2>
            <p className="mx-auto text-lg" >{paragraph}</p>
            {images.length > 0 && <ImageCarousel images={images} />}
        </div>
    );
};


const GetInvolved = () => {
    return (
        <section id="get_involved" className="pb-16">
            <HeaderImage>
                <h2 className="text-6xl font-bold text-center mb-8 text-white">Get Involved</h2>
                <div className="mb-12 text-center text-white">
                    <p className="mb-4">Looking to get more involved? We offer a variety of opportunities to engage with our community. Stay tuned for upcoming events and exciting plans!</p>
                    <p className="mb-6">Here's how you can get involved:</p>
                </div>
            </HeaderImage>
            <div className="container mx-auto px-6 py-16 md:w-9/12">
                {/* Opportunities Grid */}
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                    <GridItems
                        title="General Body Meetings (GBMs)"
                        description="Our regular meetings where you can connect with other members, learn how to invest, and get updates on ongoing projects."
                        link="gbm_details"
                        linkDescription="Learn more about GBMs"
                    />
                    <GridItems
                        title="Fund-Related Opportunities"
                        description="Learn more about how you can help out with our student-run fund."
                        link="fund_events"
                        linkDescription="Explore fund-related events"
                    />
                    <GridItems
                        title="Upcoming Events"
                        description="Don't miss out on our latest activities! From workshops to networking opportunities, stay updated on everything happening within our community."
                        link="upcoming"
                        linkDescription="See Upcoming Events"
                    />
                    <GridItems
                        title="Future Projects"
                        description="We have exciting projects in the pipeline that need your input and creativity! Be a part of shaping our future."
                        link="future_projects"
                        linkDescription="See future projects"
                    />
                    <GridItems
                        title="Membership"
                        description="Become a member and enjoy exclusive benefits, networking opportunities, and access to special events."
                        link="membership"
                        linkDescription="Become a member"
                    />
                    <GridItems
                        title="Sponsorship Opportunities"
                        description="Interested in sponsoring our events? Learn how you can support us and gain exposure for your brand."
                        link="sponsorship"
                        linkDescription="Become a sponsor"
                    />
                </div>
            {/* Sections */}
                <Sections id="gbm_details" title="General Body Meetings" paragraph="Join us for our General Body Meetings where members come together to discuss new initiatives, share ideas, and work on ongoing projects. It's a great way to stay involved and meet other members." images={[wong1, syed1, wong2, syed2]}/>
                <Sections id="fund_events" title="Fund-Related Events" paragraph="Currently, we don't have any fund-related events planned, but stay tuned! We will announce any future opportunities to get involved with our student-run fund." images={[WTC, IAwomen, NYStockExchange, triangleBuilding, WongAndSarnie, NYSEgroup]} />
                <Sections id="upcoming" title="Upcoming Events" paragraph="We are working on planning new events for the near future. Keep an eye on this section for updates about upcoming workshops, networking events, and more." images={[SyedTickets, CasinoRoom1, Food, CasinoRoom2]}/>
                <Sections id="future_projects" title="Future Projects" paragraph="There are no confirmed projects at the moment, but we're excited to announce some big initiatives soon. Stay connected to be a part of shaping future projects!" />
                <Sections id="membership" title="Membership" paragraph={<>Interested in joining? You can become a member by visiting us at the next club fair or by requesting membership through the <a href="https://mywpi.wpi.edu/Investing/club_signup" target="_blank" rel="noreferrer"> myWPI app</a>. We can't wait to have you on board!</>} />
                <Sections id="sponsorship" title="Sponsorship Opportunities" paragraph={<>Sponsorship opportunities are a great way to support our organization while gaining exposure for your brand. <Link to="/contact"> Reach out to us</Link> for more information on how you can partner with us.</>} />
                </div>
            </section>
    );
};

export default GetInvolved;

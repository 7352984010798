import React from 'react';
import { useLocation } from 'react-router-dom';

import LinkedInIcon from '../img/social_icons/LinkedIn.png';
import EmailIcon from '../img/social_icons/Email.png';

const ProfilePage = () => {
    const location = useLocation();
    const { name, image, description, linkedIn, email } = location.state || {};

    return (
        <div className="container mx-auto py-24 min-h-[75dvh] flex items-center justify-center">
            <div className="flex flex-col md:flex-row items-center mx-[15vw]">
                {/* Image on the left for larger screens and on top for smaller screens */}
                <img src={image} alt={name} className="w-[30vh] rounded-full md:mr-8 mb-4 md:mb-0" />

                {/* Content on the right for larger screens and below for smaller screens */}
                <div className="md:text-left text-center">
                    <h2 className="text-4xl font-bold mb-4">{name}</h2>
                    <p className="mb-4">{description}</p>
                    <div className="flex justify-evenly">
                        {/* Email icon */}
                        {email != null && (
                        <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
                            <img src={EmailIcon} alt="email" className="h-14 w-14" />
                        </a>
                        )}
                        {/* LinkedIn icon */}
                        {linkedIn != null && (
                        <a href={linkedIn} target="_blank" rel="noopener noreferrer">
                            <img src={LinkedInIcon} alt="linkedin" className="h-14 w-14" />
                        </a>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the mobile menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <nav className="bg-white fixed w-full shadow-md z-10">
        <div className="container mx-auto flex justify-between items-center p-4">
          {/* investWPI logo */}
          <Link className="text-xl font-bold" to="/">
            <span className="text-red-600 hover:text-red-600">invest</span><b className="text-gray-900 hover:text-gray-900">WPI</b>
          </Link>

          {/* Mobile Toggle Button */}
          <button
            type="button"
            className="block lg:hidden text-gray-500 hover:text-gray-700 focus:outline-none"
            onClick={toggleMenu}
          >
            <span className="block w-6 h-0.5 bg-gray-500 mb-1"></span>
            <span className="block w-6 h-0.5 bg-gray-500 mb-1"></span>
            <span className="block w-6 h-0.5 bg-gray-500"></span>
          </button>

          {/* Desktop Links */}
          <div className="hidden lg:flex space-x-8">
            <Link className="text-gray-700 hover:text-red-600 transition-colors duration-200" to="/get_involved" onClick={scrollToTop}>Get Involved</Link>
            <Link className="text-gray-700 hover:text-red-600 transition-colors duration-200" to="/history" onClick={scrollToTop}>History</Link>
            <Link className="text-gray-700 hover:text-red-600 transition-colors duration-200" to="/events" onClick={scrollToTop}>Events</Link>
            <Link className="text-gray-700 hover:text-red-600 transition-colors duration-200" to="/team" onClick={scrollToTop}>Our Team</Link>
            <Link className="text-gray-700 hover:text-red-600 transition-colors duration-200" to="/contact" onClick={scrollToTop}>Contact Us</Link>
          </div>
        </div>
      </nav>

      {/* Mobile Dropdown Menu */}
      {isOpen && (
        <div className="bg-white shadow-md fixed w-[15vh] top-14 rounded-md right-0 z-10 flex flex-col items-start lg:hidden">
          <Link className="block w-full text-left py-2 text-gray-700 hover:text-red-600 transition-colors duration-200 px-4" to="/get_involved">Get Involved</Link>
          <Link className="block w-full text-left py-2 text-gray-700 hover:text-red-600 transition-colors duration-200 px-4" to="/history">History</Link>
          <Link className="block w-full text-left py-2 text-gray-700 hover:text-red-600 transition-colors duration-200 px-4" to="/events">Events</Link>
          <Link className="block w-full text-left py-2 text-gray-700 hover:text-red-600 transition-colors duration-200 px-4" to="/team">Our Team</Link>
          <Link className="block w-full text-left py-2 text-gray-700 hover:text-red-600 transition-colors duration-200 px-4" to="/contact">Contact Us</Link>
        </div>
      )}
    </>
  );
};

export default NavBar;

import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderImage from './HeaderImage';
import emailjs from '@emailjs/browser';

export const ContactUs = () => {
  const form = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_qzj79xn', 'contact_form', form.current, 'h4fc5sJS-P5jtbo_r')
      .then(
        () => {
          console.log('SUCCESS!');
          setIsSubmitted(true); // Set state to show the thank-you message
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div>
        <HeaderImage>
            <h1 className="text-6xl font-bold mb-4 text-center text-white">Contact Us</h1>
            <div className="mb-12 text-center text-white">
                <p className="mb-4">Have questions or feedback? Reach out and let’s start a conversation. We're here to help and connect with you.</p>
                <p className="mb-6">Here’s how you can contact us:</p>
            </div>
        </HeaderImage>
        <div className="flex flex-col justify-center items-center py-16 p-8 min-h-[60vh]">
            {isSubmitted ? (
                <div className="text-center">
                    <h1 className="text-2xl font-bold mb-4">Thank You!</h1>
                    <p>Your message has been successfully sent. We will contact you soon.</p>
                </div>
            ) : (
                <div className="flex flex-col md:flex-row items-center justify-center gap-4 mx-auto w-full max-w-6xl">
                    <div id="left-box" className="w-full md:w-1/2 text-center p-8">
                        <h1 className="text-3xl font-bold mb-4">We'd Love to Hear from You!</h1>
                        <p className="text-1xl mb-4">
                        Please reach out to us if you have any questions! If you are trying to reach someone specific, go to <Link to="/team">our team page</Link> and locate their email that way. We look forward to hearing your thoughts!
                        </p>
                    </div>
                    <div id="right-box" className="w-full md:w-1/2">
                        <form ref={form} onSubmit={sendEmail} className="w-full max-w-lg mx-auto">
                            <div className="flex flex-col md:flex-row md:justify-between mb-4 pt-4">
                                <div className="flex flex-col mb-4 md:mb-0 md:w-[48%]">
                                    <label htmlFor="name" className="mb-2">Name:</label>
                                    <input type="text" id="name" name="user_name" className="border border-gray-300 rounded-md p-2 w-full" required />
                                </div>

                                <div className="flex flex-col md:w-[48%]">
                                    <label htmlFor="email" className="mb-2">Email:</label>
                                    <input type="email" id="email" name="user_email" className="border border-gray-300 rounded-md p-2 w-full" required />
                                </div>
                            </div>

                            <div className="mb-4">
                                <label htmlFor="message" className="mb-2 block">Message:</label>
                                <textarea id="message" name="message" rows="4" className="border border-gray-300 rounded-md p-2 w-full" required />
                            </div>

                            <div className="flex justify-center">
                                <button type="submit" className="group/button relative inline-flex items-center justify-center overflow-hidden rounded-md bg-red-600 backdrop-blur-lg px-6 py-2 text-base font-semibold text-white transition-all duration-300 ease-in-out hover:shadow-xl hover:shadow-gray-600/50 hover:text-white border border-white/20">
                                <span>Submit</span>
                                <div className="absolute inset-0 flex h-full w-full justify-center [transform:skew(-13deg)_translateX(-100%)] group-hover/button:duration-1000 group-hover/button:[transform:skew(-13deg)_translateX(100%)]">
                                    <div className="relative h-full w-10 bg-white/20"></div>
                                </div>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    </div>
  );
};

export default ContactUs;

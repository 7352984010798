import React from 'react';

const Footer = () => {
    return (
        <footer className="text-black py-4 border-t border-black">
            <div className="container mx-auto text-center">
                <p>&copy; 2024 investWPI. All Rights Reserved.</p>
                <p className="text-[0.8em]"><a href="https://www.instagram.com/ia_wpi?igsh=MjZvcnBteDh3Znhl">Instagram</a> | <a href="https://www.linkedin.com/company/wpi-investment-association/">LinkedIn</a> | <a href="mailto:iaexec@wpi.edu">iaexec@wpi.edu</a></p>
            </div>
        </footer>
    );
};

export default Footer;
